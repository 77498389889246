export const kpis = [
  {
    title: "Inventory",
    chart: "line",
    total: 31,
    currency: null,
    language: "",
    legend: [
      {
        label: "Used",
        x: "Used",
        y: 19,
      },
      {
        label: "New",
        x: "New",
        y: 12,
      },
    ],
  },
  {
    title: "Total Budget",
    chart: null,
    total: 83500,
    currency: "USD",
    language: "en-US",
    legend: [],
  },
  {
    title: "Total Sold",
    chart: "line",
    total: 75,
    currency: null,
    language: "",
    legend: [
      {
        label: "New",
        x: "New",
        y: 50,
      },
      {
        label: "Used",
        x: "Used",
        y: 25,
      },
    ],
  },
  {
    title: "Total Leads",
    chart: "line",
    total: 400,
    currency: null,
    language: "",
    legend: [
      {
        label: "New",
        x: "New",
        y: 110,
      },
      {
        label: "Existing",
        x: "Existing",
        y: 290,
      },
    ],
  },
  {
    title: "Cost per Lead",
    chart: null,
    total: 75,
    currency: "USD",
    language: "en-US",
    legend: [],
  },
  {
    title: "Cost per Sold",
    chart: null,
    total: 418,
    currency: "USD",
    language: "en-US",
    legend: [],
  },
];

export const activities = [
  {
    status: "High",
    assigned: "Autotrader",
    item: "Lead Drop",
    entry: "2022-08-18",
    open: true,
  },
  {
    status: "High",
    assigned: "Digital Agency",
    item: "Conversion Rate",
    entry: "2022-07-29",
    open: true,
  },
  {
    status: "High",
    assigned: "email Agency",
    item: "Low traffic",
    entry: "2022-07-11",
    open: false,
  },
  {
    status: "Medium",
    assigned: "In-House",
    item: "Approval",
    entry: "2022-08-01",
    open: false,
  },
  {
    status: "Low",
    assigned: "CarGurus",
    item: "Lead Drop",
    entry: "2022-08-03",
    open: true,
  },
  {
    status: "Low",
    assigned: "Digital Agency",
    item: "Bad Traffic",
    entry: "2022-07-30",
    open: false,
  },
  {
    status: "Low",
    assigned: "Autotrader",
    item: "Conversion Rate",
    entry: "2022-07-06",
    open: false,
  },
  {
    status: "Medium",
    assigned: "email Agency",
    item: "Approval",
    entry: "2022-07-08",
    open: false,
  },
  {
    status: "Medium",
    assigned: "In-House",
    item: "Conversion Rate",
    entry: "2022-07-12",
    open: false,
  },
  {
    status: "Low",
    assigned: "Digital Agency",
    item: "Bad Traffic",
    entry: "2022-08-20",
    open: false,
  },
];

export const organizations = [
  {
    name: "Organization XYZ",
  },
  {
    name: "Organization ABC",
  },
  {
    name: "Hooli inc.",
  },
];

export const messages = [
  {
    created: "",
    image: "",
    text: "This is a text message",
  },
];
export const notifications = [
  {
    created: "",
    image: "",
    text: "This is a text message",
  },
  {
    created: "",
    image: "",
    text: "This is another text message",
  },
];

export const defaultRule = JSON.stringify({
  conditions: {
    any: [
      {
        all: [
          {
            fact: "mojEmployees",
            operator: "greaterThanInclusive",
            value: 5,
          },
        ],
      },
    ],
  },
  event: {
    type: "newMojo",
    params: {
      message: "There is a new mojo employee",
    },
  },
});
