import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import  {
  IntegrationsState,
  initialIntegrationsState,
} from "./integrationsSlice";
import { components } from "api/typesgen";

interface BudgetYearsStateForTenant {
  tenantId: string;
  years: string[];
}

interface AppState {
  selectedGroupId: string;
  selectedTenantId: string;
  Roles: components["schemas"]["Role"][];
  Groups: any[];
  integrations: IntegrationsState;
  budgetYears: BudgetYearsStateForTenant[];
}

const initialState: AppState = {
  Groups: [],
  selectedGroupId: "",
  selectedTenantId: "",
  Roles: [],
  integrations: initialIntegrationsState,
  budgetYears: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    selectTenantId: (state, action: PayloadAction<string>) => {
      state.selectedTenantId = action.payload;
    },
    selectGroupId: (state, action: PayloadAction<string>) => {
      state.selectedGroupId = action.payload;
    },
    setRoles: (state, action: PayloadAction<components["schemas"]["Role"][]>) => {
      state.Roles = action.payload;
    },
    setGroups: (state, action: PayloadAction<any[]>) => {
      state.Groups = action.payload;
    },
    setBudgetYearsForTenant: (state, action: PayloadAction<BudgetYearsStateForTenant>) => {
      const node = state.budgetYears.find(x => x.tenantId === action.payload.tenantId);
      if (node === undefined) {
        state.budgetYears.push({ tenantId: action.payload.tenantId, years: action.payload.years });
      } else {
        node.years = action.payload.years;
      }
    }
  },
});

export const { selectTenantId, selectGroupId, setRoles, setGroups, setBudgetYearsForTenant } = appSlice.actions;

export default appSlice;
