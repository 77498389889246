import { useClickAway } from "react-use";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Editor from "react-simple-code-editor";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

export default function CodeEditorModal(props: {
  modalOpen: boolean;
  json: string;
  setModalOpen: (open: boolean) => void;
  setJSON: (open: string) => void;
}) {
  const toast = useToast();
  const codeEditorRef = useRef(null);
  const [isJSONValid, setIsJSONValid] = useState(true);

  useClickAway(codeEditorRef, () => {
    if (!props.modalOpen) return;

    if (isJSONValid || !props.json) {
      props.setModalOpen(false);
    } else {
      toast({
        title: `Invalid JSON`,
        status: "error",
        position: "bottom-left",
      });
    }
  });

  useEffect(() => {
    if (!props.json) return setIsJSONValid(true);
    try {
      JSON.parse(props.json);
      setIsJSONValid(true);
    } catch (err) {
      setIsJSONValid(false);
    }
  }, [props.json]);

  return (
    <section
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(140, 130, 130, 0.4)",
        position: "fixed",
        top: "0",
        left: "0",
        justifyContent: "center",
        alignItems: "center",
        display: props.modalOpen ? "flex" : "none",
        zIndex: 99999999,
      }}
    >
      <div
        ref={codeEditorRef}
        style={{
          height: "700px",
          width: "700px",
          overflow: "scroll",
          border: `1px solid ${isJSONValid ? "rgb(49, 130, 206)" : "red"}`,
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "5px 5px 10px rgb(225, 225, 225, 0.5)",
        }}
      >
        <Editor
          value={props.json}
          onValueChange={(value) => props.setJSON(value)}
          highlight={(code) => highlight(code, languages.js)}
          padding={20}
          placeholder={"{...}"}
          textareaClassName="code-editor__textarea"
          style={{
            minHeight: "500px",
            borderRadius: "10px",
          }}
        />
      </div>
    </section>
  );
}
