import { useParams } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { components } from 'api/typesgen';
import { useMojoEffect } from 'api/useMojoEffect';

const containerStyle = {
    width: '100%',
    height: '100%'
};

type Tenant = components['schemas']['Tenant'];

export function ClientInfo() {
    const { tenantId } = useParams();
    const [client, setClient] = useState<Tenant | null>(null);
    const [center, setCenter] = useState({
        lat: 39.8283,  // Default the center point to the US when unknown
        lng: -98.5795
    });

    const { runWithId } = useMojoEffect('/tenants/', 'GET');

    useEffect(() => {
        const fetchClient = async () => {
            if (!tenantId) return;

            const [data, error] = await runWithId(tenantId);
            if (error) {
                console.error('Error fetching client:', error.message);
                return;
            }

            setClient(data);
            if (data.lat && data.lng) {
                setCenter({
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng)
                });
            }
        };

        fetchClient();
    }, [tenantId, runWithId]);

    return (
        <Flex className="client-info--tab">
            <Flex className="client-info--map">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                >
                    {client && client.lat && client.lng && (
                        <Marker
                            position={{
                                lat: parseFloat(client.lat),
                                lng: parseFloat(client.lng)
                            }}
                        />
                    )}
                </GoogleMap>
            </Flex>
        </Flex>
    );
}