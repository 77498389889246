import {
  Box,
  FormControl,
  useToast,
  HStack,
  Textarea,
  Tooltip,
  Select,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

import { useMojoEffect } from "api/useMojoEffect";
import { FloatingLabelInput } from "components/FloatingLabelInput";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FacebookOauth } from "./FacebookOauthButton";
import {
  GoogleServiceAccountButton,
  updateDriveCentricProps,
  updateFortellisEleadsProps,
  updateGoogleAnalyticsProps,
  UpdateGooglePropsButton,
  updateVautoProps,
} from "./GoogleOauthButton";
import {
  allTenantIntegrations,
  TenantIntegrationData,
} from "data/allIntegrations";
import AddRulesModal from "components/AddRulesModal";

export type AddEditIntegrationFormProps = {
  tenantId: string;
  data: TenantIntegrationData[];
  refetch: () => void;
};

export function AddEditIntegrationForm({
  data,
  tenantId,
  refetch,
}: AddEditIntegrationFormProps) {
  //const [defaultValues, setDefaultValues] = useState();
  const [integrationType, setIntegrationType] = useState("");
  const [integrationId, setIntegrationId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [code, setCode] = useState("");

  const toast = useToast();

  const { run: runCreate } = useMojoEffect(
    "/api/v1/TenantIntegrations/create",
    "post"
  );
  const { control, register, handleSubmit, formState, reset, watch, setValue } =
    useForm();

  const useFormBuilder = ({
    register,
    isLoading,
    formState,
    formValues,
    integrationId,
    refetch,
  }) => {
    const formFields = {
      [allTenantIntegrations.facebook.id]: [
        {
          type: "button",
          name: "Facebook Oauth",
          component: (
            <Box>
              <FacebookOauth tenantId={tenantId} />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.googleAds.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <Tooltip label="The Google Ads Customer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Customer ID"}
                  {...register("customerId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "input",
          name: "Linked Customer ID",
          component: (
            <Box>
              <Tooltip label="The Google Ads Customer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Linked Customer ID"}
                  {...register("linkedCustomerId", { required: false })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Update Google Ads Props",
          component: (
            <Box>
              <UpdateGooglePropsButton
                type={"ads"}
                integrationId={integrationId}
                isLoading={isLoading}
                formValues={formValues}
                refetch={async () => {
                  reset();
                  await refetch();
                }}
              />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.googleService.id]: [
        {
          type: "input",
          name: "Service Account Credentials",
          component: (
            <Box>
              <Textarea
                isRequired
                size="sm"
                h={20}
                placeholder="Credentials"
                {...register("gCredentials", { required: true })}
              />
            </Box>
          ),
        },
        {
          type: "button",
          name: "Connect Service Account",
          component: (
            <Box marginTop={"-40px !important"}>
              <GoogleServiceAccountButton
                integrationId={integrationId}
                isLoading={isLoading}
                formValues={formValues}
                onSubmit={async () => {
                  reset();
                  await refetch();
                }}
              />
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.vautoInventory.id]: [
        {
          type: "input",
          name: "VCustomer ID",
          component: (
            <Box>
              <Tooltip label="The VAuto Dealer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"vAuto Dealer ID"}
                  {...register("dealerId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { dealerId } = formValues;
                  await updateVautoProps(toast, { dealerId });
                  refetch();
                  reset();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      [allTenantIntegrations.driveCentricCRM.id]: [
        {
          type: "input",
          name: "DCCustomer ID",
          component: (
            <Box>
              <Tooltip label="The DriveCentruc Dealer ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"DriveCentric Dealer ID"}
                  {...register("dcDealerId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { dcDealerId } = formValues;
                  await updateDriveCentricProps(toast, { dcDealerId });
                  await refetch();
                  reset();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      [allTenantIntegrations.googleAnalytics.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <Tooltip label="The Property ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Property ID"}
                  {...register("propertyId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { propertyId } = formValues;
                  await updateGoogleAnalyticsProps(toast, { propertyId });
                  await refetch();
                  reset();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      [allTenantIntegrations.rules.id]: [
        {
          type: "input",
          name: "Customer ID",
          component: (
            <Box>
              <IconButton
                aria-label=""
                variant="mojoPrimary"
                type="submit"
                onClick={() => setCodeModalOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          ),
        },
      ],
      [allTenantIntegrations.eLeads.id]: [
        {
          type: "input",
          name: "Subscription ID",
          component: (
            <Box>
              <Tooltip label="The Fortellis Subscription ID">
                <FloatingLabelInput
                  isRequired
                  placeholder={"Subscription ID"}
                  {...register("eLeadsSubscriptionId", { required: true })}
                />
              </Tooltip>
            </Box>
          ),
        },
        {
          type: "button",
          name: "Submit Button",
          component: (
            <div className="bt-add">
              <IconButton
                aria-label=""
                isLoading={isLoading}
                variant="mojoPrimary"
                type="submit"
                onClick={async (e) => {
                  const { eLeadsSubscriptionId } = formValues;
                  await updateFortellisEleadsProps(toast, { eLeadsSubscriptionId });
                  await refetch();
                  reset();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ),
        },
      ],
      default: [
        // {
        //   type: "input",
        //   name: "Secret Key",
        //   component: (
        //     <FloatingLabelInput
        //       placeholder="Public Key"
        //       {...register("publicKey")}
        //     />
        //   ),
        // },
        // {
        //   type: "input",
        //   name: "Secret Key",
        //   component: (
        //     <FloatingLabelInput
        //       placeholder="Secret Key"
        //       {...register("secretKey")}
        //     />
        //   ),
        // },
        // {
        //   type: "button",
        //   name: "Submit Button",
        //   component: (
        //     <div className="bt-add">
        //       <Button isLoading={isLoading} variant="mojoPrimary" type="submit">
        //         <AddIcon />
        //       </Button>
        //     </div>
        //   ),
        // },
      ],
    };

    return { formFields };
  };

  // useEffect(() => {
  //   let get = async () => {
  //     try {
  //       let googleProps = await getGoogleProps(integrationType);
  //       reset();
  //       reset({
  //         property: googleProps.property,
  //         customerId: googleProps.customerId,
  //         linkedCustomerId: googleProps.linkedCustomerId,
  //       });
  //     } catch (error) {
  //       console.error("Error fetching Google props:", error);
  //     }
  //   };
  //   get();
  // }, [integrationType]);

  const { errors } = formState;
  const formValues = watch();

  const { formFields } = useFormBuilder({
    register,
    formState,
    formValues,
    integrationId,
    refetch,
    isLoading: isLoading,
  });

  const integrationsData = Object.values(allTenantIntegrations).map(
    ({ id: IntegrationId, name }) => ({
      name,
      IntegrationId,
    })
  );

  const integrationOptions = integrationsData
    ? integrationsData.map(({ IntegrationId, name }) => ({
        label: name,
        value: IntegrationId,
      }))
    : [];

  useEffect(() => {
    register("IntegrationId");
  }, [register]);

  useEffect(() => {
    const { IntegrationId } = formValues;

    integrationsData?.forEach((elem) => {
      if (IntegrationId === elem.IntegrationId) {
        setIntegrationType(elem.IntegrationId);
        setIntegrationId(IntegrationId);
      }
    });
  }, [formValues]);

  const buildForm = (integrationType) => {
    if (!formFields[integrationType]) {
      integrationType = "default";
    }
    const components = formFields[integrationType]?.map((elem) => {
      return elem?.component;
    });

    return <>{components?.map((c) => c)}</>;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          const [createData, error] = await runCreate({
            ...data,
            TenantId: tenantId,
            IntegrationId: data.IntegrationId,
          });
          if (error === null) {
            await refetch();
            reset();
            toast({
              title: "Integration created",
              status: "success",
            });
          } else {
            toast({
              title: "Integration creation failed",
              status: "error",
            });
          }
          return createData;
        })}
      >
        <FormControl isInvalid={!!errors.username}>
          <div className="form-upper-wrapper">
            <div className="form-upper form">
              <div className="form-upper-title">ADD NEW INTEGRATION</div>
            </div>
            <div className="form">
              <div className="input-group min-width-20">
                <Select
                  className="min-width-20"
                  placeholder="Type"
                  {...register("IntegrationId", { required: true })}
                >
                  {integrationOptions.map((j) => (
                    <option value={j.value}>{j.label}</option>
                  ))}
                </Select>
              </div>
              <HStack spacing="10px">{buildForm(integrationType)}</HStack>
            </div>
          </div>
        </FormControl>
      </form>

      <AddRulesModal
        integrations={data}
        modalOpen={codeModalOpen}
        setModalOpen={setCodeModalOpen}
        code={code}
        setCode={setCode}
        refetch={refetch}
      />
    </>
  );
}
