import {
    Button,
    FormControl,
    Flex,
    Card,
    useColorModeValue,
    Switch,
    FormLabel,
    Select,
} from '@chakra-ui/react';
import { components } from 'api/typesgen';
import { Select as Multi, useSelect } from 'components/Select';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FloatingLabelInput } from '../../../components/FloatingLabelInput';

import './ClientForm.scss';
import allStatesData from 'data/allStates';
import AddressAutocomplete from 'components/AddressAutocomplete';

const labelProps = { transform: 'scale(0.85) translateY(-24px)' };

export type ClientFormProps<T = components['schemas']['Tenant']> = {
    submit: (data: T) => Promise<any>;
    values?: T;
    defaultValues?: Partial<T>;
    btnTitle: string;
    btnIcon: ReactElement;
    formTitle: string;
    onlyRequired?: boolean;
    isSubmitting?: boolean;
    isLoading?: boolean;
    clientTypesData: any[];
    brandsData: any[];
    clientGroupsData: any[];
};

export function ClientForm({
    submit,
    values,
    defaultValues,
    btnTitle,
    btnIcon,
    isLoading,
    isSubmitting,
    onlyRequired,
    clientTypesData,
    brandsData,
    clientGroupsData,
}: ClientFormProps) {

    const [isFormDirty, setIsFormDirty] = useState(false);
    const statesData = allStatesData;

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        setValue,
        reset,
        control
    } = useForm<NonNullable<typeof values>>({
        values,
        defaultValues,
    });

    const { registerSelect } = useSelect<NonNullable<typeof values>>({
        values,
        setValue,
    });

    const brandOptions = brandsData
        ? brandsData.map(({ BrandId, name }) => ({ label: name, value: BrandId }))
        : [];

    const clientTypeOptions = clientTypesData
        ? clientTypesData.map(({ TenantTypeId, name }) => ({
            label: name,
            value: TenantTypeId,
        }))
        : [];

    const clientGroupOptions = clientGroupsData
        ? clientGroupsData.map(({ id, name }) => ({
            label: name,
            value: id,
        }))
        : [];

    const statesOptions = statesData
        ? statesData.map(({ name, value }) => ({ label: name, value: value }))
        : [];

    const isInvalid =
        !!errors.name ||
        !!errors.TenantTypeId ||
        !!errors.TenantBrandIds ||
        !!errors.TenantGroupId;
    /*
      useEffect(() => {
        register('TenantTypeId');
        register('TenantGroupId');
        register('TenantBrandIds');
      }, [register]);
    
      useEffect(() => {
        setIsFormDirty(isDirty);
      }, [isDirty]);
    */
    const buttonEnabled =
        isFormDirty && !isInvalid && !isLoading && !isSubmitting;

    const buttonIsLoading = isLoading || isSubmitting;
    const buttonLoadingText = isLoading ? 'LOADING' : undefined;

    const bg = useColorModeValue('white.100', 'black.100');
    const color = useColorModeValue('black.700', 'white.100');
    const [activeFlag, setActiveFlag] = useState(
        values === undefined ? true : values.activeFlag
    );

    useEffect(() => {
        setActiveFlag(values === undefined ? true : values.activeFlag);
        reset(values);
    }, [reset, values]); //hack because the form values do not always bind

    return (
      <Card p={18} bg={bg} w={500}>
        <form
          onSubmit={(data: any) => {
            setIsFormDirty(false);
            handleSubmit(submit)(data);
          }}
        >
          <FormControl className='client-form' isInvalid={isInvalid}>
            <div className='client-form-subheader'>
              <h2>CLIENT DETAILS</h2>
              <div className='left-form-switchs'>
                {onlyRequired ? (
                  <></>
                ) : (
                  <>
                    <FormLabel htmlFor='client-active' mb='0'>
                      Active?
                    </FormLabel>
                    <Switch
                      size='md'
                      isChecked={activeFlag}
                      {...register('activeFlag')}
                      onChange={(e) => {
                        setIsFormDirty(true);
                        setActiveFlag(e.target.checked);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className='client-form-body'>
              <FloatingLabelInput
                placeholder='Client Name'
                isRequired={true}
                isInvalid={!!errors.name}
                {...register('name', {
                  required: 'This is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                })}
                onChange={(e) => {
                  setIsFormDirty(e.target.value !== defaultValues?.name);
                }}
              />

              <FormControl variant='floating' size='md' isRequired>
                <Flex
                  width={'100%'}
                  gap={'10px'}
                  justifyContent={'space-between'}
                >
                  <Select
                    color={color}
                    placeholder='Select...'
                    {...register('TenantGroupId')}
                    className='client-group'
                    onChange={(e) => {
                      setIsFormDirty(
                        e.target.value !== defaultValues?.TenantGroupId
                      );
                    }}
                  >
                    {clientGroupOptions.map((o) => (
                      <option value={o.value}>{o.label}</option>
                    ))}
                  </Select>
                  <FormLabel
                    style={{ paddingRight: '8px' }}
                    bg={bg}
                    color={color}
                  >
                    Client Group
                  </FormLabel>
                </Flex>
              </FormControl>

              <FormControl variant='floating' size='md' isRequired>
                <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                  Client Brands
                </FormLabel>
                <Multi
                  id='myMulti'
                  //placeholder='Client Brands'
                  isInvalid={!!errors.TenantTypeId}
                  {...registerSelect('TenantBrandIds', {
                    transform: (t) => {
                      setIsFormDirty(true);
                      return t;
                    },
                    options: brandOptions,
                    isMulti: true,
                    required: true,
                  })}
                />
              </FormControl>

              <FloatingLabelInput
                placeholder='Client Website'
                {...register('mainWebsiteUrl')}
                onChange={(e) => {
                  setIsFormDirty(
                    e.target.value !== defaultValues?.mainWebsiteUrl 
                  );
                }}
              />

              <FormControl variant='floating' size='md' isRequired>
                <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                  Client Type
                </FormLabel>
                <Select
                  placeholder='Select...'
                  color={color}
                  {...register('TenantTypeId')}
                  onChange={(e) => {
                    setIsFormDirty(
                      e.target.value !== defaultValues?.TenantTypeId
                    );
                  }}
                >
                  {clientTypeOptions.map((o) => (
                    <option value={o.value}>{o.label}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant='floating' size='md' isRequired>
                <FormLabel style={{ paddingRight: '8px' }} {...labelProps}>
                  State
                </FormLabel>
                <Select
                  placeholder='Select...'
                  color={color}
                  {...register('state')}
                  onChange={(e) => {
                    setIsFormDirty(e.target.value !== defaultValues?.state);
                  }}
                >
                  {statesOptions.map((o) => (
                    <option value={o.value}>{o.label}</option>
                  ))}
                </Select>
              </FormControl>

              <FloatingLabelInput
                isRequired
                placeholder='Client Zip (5 digits)'
                {...register('zip')}
                onChange={(e) => {
                  setIsFormDirty(e.target.value !== defaultValues?.zip);
                }}
              />
              {onlyRequired ? (
                <></>
              ) : (
                <>
                  <FloatingLabelInput
                    placeholder='Client Code'
                    {...register('tenantCode')}
                    onChange={(e) => {
                      setIsFormDirty(
                        e.target.value !== defaultValues?.tenantCode
                      );
                    }}
                  />
                  <FloatingLabelInput
                    placeholder='Client NickName'
                    {...register('tenantNickName')}
                    onChange={(e) => {
                      setIsFormDirty(
                        e.target.value !== defaultValues?.tenantNickName
                      );
                    }}
                  />
                  <FloatingLabelInput
                    placeholder='Client Slogan'
                    {...register('tenantSlogan')}
                    onChange={(e) => {
                      setIsFormDirty(
                        e.target.value !== defaultValues?.tenantSlogan
                      );
                    }}
                  />
                  <FloatingLabelInput
                    placeholder='Client phone Number'
                    {...register('phone')}
                    onChange={(e) => {
                      setIsFormDirty(e.target.value !== defaultValues?.phone);
                    }}
                  />
                  {/* <FloatingLabelInput
                                    placeholder='Client Address'
                                    {...register('address')}
                                /> */}
                  <AddressAutocomplete
                    className='client-address-autocomplete'
                    placeholder='Client Address'
                    control={control}
                    name='address'
                    bg={bg}
                    color={color}
                    onChange={(addressData) => {
                      setValue('address', addressData.address);
                      setValue('city', addressData.city);
                      setValue('state', addressData.state);
                      setValue('zip', addressData.zip);
                      setValue('lat', addressData.lat.toString());
                      setValue('lng', addressData.lng.toString());
                      setIsFormDirty(true);
                    }}
                  />
                  <FloatingLabelInput
                    placeholder='Client City'
                    {...register('city')}
                    onChange={(e) => {
                      setIsFormDirty(e.target.value !== defaultValues?.city);
                    }}
                  />
                  <FloatingLabelInput 
                    className='client-lat'
                    placeholder='Client Lat'
                    type='hidden'
                    {...register('lat')}
                    onChange={(e) => {
                      setIsFormDirty(e.target.value !== defaultValues?.lat);
                    }}
                  />
                  <FloatingLabelInput
                    className='client-lng'
                    placeholder='Client Lng'
                    type='hidden'
                    {...register('lng')}
                    onChange={(e) => {
                      setIsFormDirty(e.target.value !== defaultValues?.lng);
                    }}
                  />
                  <FloatingLabelInput
                    placeholder='Client Dealership USPS'
                    {...register('tenantDealerShipUSPS')}
                    onChange={(e) => {
                      setIsFormDirty(
                        e.target.value !== defaultValues?.tenantDealerShipUSPS
                      );
                    }}
                  />
                </>
              )}
            </div>
            <div className='client-form-footer'>
              <Button
                isLoading={buttonIsLoading}
                isDisabled={!buttonEnabled}
                leftIcon={btnIcon}
                loadingText={buttonLoadingText}
                variant='mojoPrimary'
                type='submit'
              >
                {btnTitle}
              </Button>
            </div>
          </FormControl>
        </form>
      </Card>
    );
}
