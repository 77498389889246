import { useToast } from "@chakra-ui/react";
import { VendorsDataTable } from "../VendorDataTable";
import { AddEditVendorForm } from "../AddEditVendorForm";
import { useParams } from "react-router-dom";
import { useMojoFetch } from "api/useMojoFetch";
import { useMojoEffect } from "api/useMojoEffect";
import { ArrayElement } from "lib/types/utils";
import { useState } from "react";

function TenantVendors() {
  const { tenantId } = useParams();
  const [vendorId, setVendorId] = useState('');
  const { error, data, refetch } = useMojoFetch(`/api/v1/TenantVendors/all/${tenantId}`, 'get');
  const { run: runDelete} = useMojoEffect(`/api/v1/TenantVendors/${vendorId}`, 'delete');
  const { run: runUpdate } = useMojoEffect(`/api/v1/TenantVendors/${vendorId}`, 'patch');
  const toast = useToast();

  const deleteVendor = async (vendor) => {
    setVendorId(vendor.VendorId);
    const [body, error] = await runDelete()
    if (error === null) {
      toast({
          title: 'Vendor deleted',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to delete vendor',
          status: 'error',
      });
    }
  }

  const updateVendor = async (idx: number, vendor) => {
    if (!data) {
      return;
    }
    const fullVendor = {
      ...data[idx],
      ...vendor,
    }
    setVendorId(idx + '');
    const [updateDate, error] = await runUpdate(fullVendor);
    if (error === null) {
      toast({
          title: 'Vendor updated',
          status: 'success',
      });
      await refetch();
    } else {
      toast({
          title: 'Failed to update vendor',
          status: 'error',
      });
    }
  }
  
  return (
    <>
      {/* <AddEditVendorForm
        tenantId={tenantId || ''}
        refetch={refetch}
        clientVendors={data || []}
      /> */}
      <VendorsDataTable
        data={data}
        error={error}
        deleteVendor={deleteVendor}
        updateVendor={updateVendor}
      />
    </>
  );
}

export default TenantVendors;
