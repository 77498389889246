import {
  Box,
  useColorModeValue,
  Flex,
  Heading,
  Select,
  Button,
  Spinner,
  useToast,
  Center,
  Image,
} from '@chakra-ui/react';
import './Budgets.scss';
import YearSelector from 'components/Button/YearSelector';
import { useEffect, useState } from 'react';
import { useMojoEffect } from 'api/useMojoEffect';
import AccordionComponent from 'components/Accordion/AccordionComponent';
import BudgetTable from '../BudgetTable/BudgetTable';
import { BudgetsUploadForm } from '../BudgetsUploadForm/BudgetsUploadForm';
import { useStore } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { setBudgetYearsForTenant } from 'store/reducers/appSlice';
import logo from '../../../logo-teal.png';

function Budgets({
  clients,
  departments,
  vendors,
  channels,
  channelTypes,
  media,
  objectives,
  audiences,
  loc,
  loc_name,
  isUploading,
  setUploading,
}) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const store = useStore();
  const { budgetYears } = (store.getState() as any).app;
  const [isloctLoading, setLoctLoading] = useState(false);

  const [location, setLocation] = useState(loc);
  const [location_name, setLocationName] = useState(loc_name);
  const [selectedYears, setYears] = useState<Set<string>>(new Set<string>());
  const [myKey, setKey] = useState(1);
  const [fetchError, setError] = useState(null as any);
  const [budgets, setBudgets] = useState<Map<string, []>>(new Map());

  const { runWithId: getBudgets } = useMojoEffect(`/api/v1/Budgets?`, 'get');

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('budgetTableColumnVisibility');
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : [
          'location',
          'objective',
          'type',
          'audience',
          'department',
          'channel',
          'medium',
          'partner',
          'notes',
          'coop_eligible',
          'coop_percentage',
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
          'avg',
          'total',
          'actions',
        ];
  });
  const updateColumnVisibility = (newVisibility) => {
    setColumnVisibility(newVisibility);
    localStorage.setItem(
      'budgetTableColumnVisibility',
      JSON.stringify(newVisibility)
    );
  };

  const doneUploading = async () => {
    setUploading(false);
    await doLocationChange(location_name);
  };

  const doLocationChange = async (newValue) => {
    
    const myBudgets = new Map<string, []>();
    if (newValue === '') {
      setLocation('');
      setLocationName('');
      localStorage.setItem('location', '');
      localStorage.setItem('location_name', '');
      for (const year of Array.from(selectedYears)) {
        myBudgets.set(year, []);
      }
      setBudgets(myBudgets);
      setKey(myKey + 1);
      return;
    }
    const tnt = clients.find((c: any) => c.name === newValue);
    if (tnt === undefined) {
      return;
    }
    setLoctLoading(true);
    const loc = tnt.TenantId;
    setLocation(loc);
    setLocationName(newValue);
    localStorage.setItem('location', loc);
    localStorage.setItem('location_name', newValue);

    const [full_data, errors] = await getBudgets(`tenantId=${loc}`);
    if (errors !== null) {
      toast({
        title: `Failed to fetch budgets for tenant ${newValue}`,
        status: 'error',
      });
      setLoctLoading(false);
      return;
    }

    let yearsArray: string[] = [];
    const node = budgetYears
      ? budgetYears.find((x) => x.tenantId === loc)
      : undefined;
    if (node === undefined) {
      full_data.forEach((d) => {
        yearsArray.push(d.year);
      });
    } else {
      yearsArray = node.years;
    }
    yearsArray = [...yearsArray, new Date().getFullYear() + ''];
    setYears(new Set<string>(yearsArray.sort().reverse()));
    for (const year of yearsArray) {
      const data = full_data.filter((d) => d.year === year);

      //const [data, errors] = await getBudgets(`tenantId=${loc}&year=${year}`);

      const budget = data.map((d) => {
        const result = { ...d };

        const vendor = vendors.find((v) => v.unique_name === d.partner);
        if (vendor === undefined) {
          result.partner_name = `${d.partner} (not found)`;
        } else {
          result.partner_name = vendor.name;
        }

        const channel = channels.find(
          (c) =>
            c.name.toLowerCase().replace(/\s+/g, '') ===
            d.channel.toLowerCase().replace(/\s+/g, '')
        );
        if (channel === undefined) {
          result.channel = `${d.channel} (not found)`;
        } else {
          result.channel = channel.name;
        }

        const channelType = channelTypes.find(
          (t) =>
            t.name.toLowerCase().replace(/\s+/g, '') ===
            d.type.toLowerCase().replace(/\s+/g, '')
        );
        if (channelType === undefined) {
          result.type = `${d.type} (not found)`;
        } else {
          result.type = channelType.name;
        }

        const medium = media.find(
          (m) =>
            m.name.toLowerCase().replace(/\s+/g, '') ===
            d.medium.toLowerCase().replace(/\s+/g, '')
        );
        if (medium === undefined) {
          result.medium = `${d.medium} (not found)`;
        } else {
          result.medium = medium.name;
        }

        const objective = objectives.find(
          (o) =>
            o.name.toLowerCase().replace(/\s+/g, '') ===
            d.objective.toLowerCase().replace(/\s+/g, '')
        );
        if (objective === undefined) {
          result.objective = `${d.objective} (not found)`;
        } else {
          result.objective = objective.name;
        }

        const audience = audiences.find(
          (o) =>
            o.name.toLowerCase().replace(/\s+/g, '') ===
            d.audience.toLowerCase().replace(/\s+/g, '')
        );
        if (audience === undefined) {
          result.audience = `${d.audience} (not found)`;
        } else {
          result.audience = audience.name;
        }

        const department = departments.find(
          (dept) =>
            dept.name.toLowerCase().replace(/\s+/g, '') ===
            d.department.toLowerCase().replace(/\s+/g, '')
        );
        if (department === undefined) {
          result.department = `${d.department} (not found)`;
        } else {
          result.department = department.name;
        }
        return result;
      });
      myBudgets.set(year, budget);
    }
    setBudgets(myBudgets);
    setKey(myKey + 1);
    setLoctLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      const myTenant = clients.find((x) => x.TenantId === location);
      if (myTenant === undefined) {
        setBudgets(new Map());
        setLocation('');
        setLocationName('');
        setKey((prevKey) => {
          return prevKey + 1;
        });
      } else {
        await doLocationChange(location_name);
      }
    };
    console.log('inside1');
    fetch();
  }, [clients, location, location_name]);

  const handleLocationChange = async (e) => {
    await doLocationChange(e.target.value);
  };

  async function setSelectedYear(year: string | null) {
    if (year != null) {
      selectedYears.add(year);
      const sortedYearsArray = Array.from(selectedYears.keys())
        .sort()
        .reverse();
      setYears(new Set(sortedYearsArray));
      dispatch(
        setBudgetYearsForTenant({
          tenantId: location,
          years: sortedYearsArray,
        })
      );
    }
  }

  //light/dark mode
  const bg = useColorModeValue('white.100', '#121212');
  const filterBg = useColorModeValue('white', '#282828');
  const fontColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', '#3f3f3f');

  if (isloctLoading) {
    return (
      <Center bg='white' h={'100%'} w={'100%'} position={'fixed'}>
        <Flex justify='center' direction='column'>
          <Image src={logo} alt='Mojo Platform' />
          <Box className='loader'></Box>
          <Center>Loading...</Center>
        </Flex>
      </Center>
    );
  }

  if (isUploading) {
    return (
      <BudgetsUploadForm
        doneUploading={doneUploading}
        location_id={location}
        location_name={location_name}
        vendors={vendors}
        departments={departments}
        channels={channels}
        channelTypes={channelTypes}
        media={media}
        audiences={audiences}
        objectives={objectives}
        setSelectedYear={setSelectedYear}
      />
    );
  }
  return (
    <Box className='budgets-page--wrapper' bg={bg}>
      <Flex className='budgets-page--header'>
        <Flex className='budgets-header--left'>
          <Heading className='budgets-heading'>BUDGETS</Heading>
        </Flex>
        <Flex className='budgets-header--right'>
          <Flex className='location-select-wrapper'>
            {isloctLoading && <Spinner />}
            {!isloctLoading && (
              <Select
                value={location_name}
                className='location-select'
                placeholder='Location'
                aria-label='location'
                onChange={(e) => handleLocationChange(e)}
                bg={filterBg}
                border={borderColor}
                _placeholder={{ color: fontColor, opacity: 1 }}
              >
                {fetchError === null &&
                  clients.map((client) => {
                    return (
                      <option value={client.name} key={client.name}>
                        {client.name}
                      </option>
                    );
                  })}
              </Select>
            )}
          </Flex>
          <Flex className='budgets-header--buttons'>
            <YearSelector
              className='year-selector'
              title='Add Budgets Year'
              setSelectedYear={setSelectedYear}
            />
            <Button
              isDisabled={location_name === ''}
              className='budgets-upload-btn primary-btn'
              onClick={() => {
                setUploading(true);
              }}
            >
              Upload Budget
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex className='budgets-page--body'>
        {Array.from(selectedYears).map((year, i) => (
          <AccordionComponent
            title={`${year} Budget`}
            key={`accordion-${year}`}
          >
            <BudgetTable
              key={myKey + i}
              data={budgets.get(year) === undefined ? [] : budgets.get(year)}
              vendors={vendors}
              departments={departments}
              channels={channels}
              media={media}
              channelTypes={channelTypes}
              objectives={objectives}
              audiences={audiences}
              year={year}
              location={location}
              location_name={location_name}
              columnVisibility={columnVisibility}
              setColumnVisibility={updateColumnVisibility}
            />
          </AccordionComponent>
        ))}
      </Flex>
    </Box>
  );
}
export default Budgets;
