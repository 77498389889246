import {
    Box,
    useColorModeValue,
    Flex,
    Heading,
    Button,
    Select,
    Spinner,
    useToast,
} from '@chakra-ui/react';
import './Goals.scss';
import YearSelector from 'components/Button/YearSelector';
import { useEffect, useState } from 'react';
import GoalsTable from '../GoalsTable/GoalsTable';
import { useMojoEffect } from 'api/useMojoEffect';
import AccordionComponent from 'components/Accordion/AccordionComponent';
import { GoalsUploadForm } from '../GoalsUploadForm/GoalsUploadForm';

function Goals({
    loc,
    loc_name,
    clients,
    goalTypes,
    departments,
    isUploading,
    setUploading,
}) {
    const toast = useToast();

    const [location, setLocation] = useState(loc);
    const [location_name, setLocationName] = useState(loc_name);
    const [goals, setGoals] = useState<Map<string, any[]>>(new Map());
    const [selectedYears, setYears] = useState<Set<string>>(
        new Set<string>([new Date().getFullYear().toString()])
    );
    const [myKey, setKey] = useState(1);
    const { runWithId: getGoals } = useMojoEffect(`/api/v1/Goals?`, 'get');

    const [columnVisibility, setColumnVisibility] = useState(() => {
        const savedVisibility = localStorage.getItem('goalsTableColumnVisibility');
        return savedVisibility
            ? JSON.parse(savedVisibility)
            : [
                'location',
                'type',
                'department',
                'notes',
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december',
                'avg',
                'total',
                'actions',
            ];
    });
    const updateColumnVisibility = (newVisibility) => {
        setColumnVisibility(newVisibility);
        localStorage.setItem(
            'goalsTableColumnVisibility',
            JSON.stringify(newVisibility)
        );
    };

    useEffect(() => {
        const fetch = async () => {
            const myTenant = clients.find((x) => x.TenantId === location);
            if (myTenant === undefined) {
                setGoals(new Map());
                setLocation('');
                setLocationName('');
                setKey((prevKey) => {
                    return prevKey + 1;
                });
            } else {
                await doLocationChange(location_name);
            }
        };
        fetch();
    }, [clients, location]);

    const doneUploading = async () => {
        setUploading(false);
        await doLocationChange(location_name);
    };

    const handleLocationChange = async (e) => {
        await doLocationChange(e.target.value);
    };

    const doLocationChange = async (newValue) => {
        const myGoals = new Map<string, any[]>();
        if (newValue === '') {
            setLocation('');
            setLocationName('');
            localStorage.setItem('location', '');
            localStorage.setItem('location_name', '');
            for (const year of Array.from(selectedYears)) {
                myGoals.set(year, []);
            }
            setGoals(myGoals);
            setKey(myKey + 1);
            return;
        }
        const selectedClient = clients.find((c: any) => c.name === newValue);
        if (!selectedClient) return;

        const loc = selectedClient.TenantId;
        setLocation(loc);
        setLocationName(newValue);
        localStorage.setItem('location', loc);
        localStorage.setItem('location_name', newValue);
        for (const year of Array.from(selectedYears)) {
            const [data, errors] = await getGoals(`tenantId=${loc}&year=${year}`);
            if (errors) {
                //console.error(`Error fetching goals for ${year}:`, errors);
                toast({
                    title: `Failed to fetch year ${year} and tenant ${newValue}`,
                    status: 'error',
                });
                continue;
            }
            const goal = data.map((d) => {
                const result = { ...d };

                const channelType = goalTypes.find(
                    (t) =>
                        t.name.toLowerCase().replace(/\s+/g, '') ===
                        d.type.toLowerCase().replace(/\s+/g, '')
                );
                if (channelType === undefined) {
                    result.type = `${d.type} (not found)`;
                } else {
                    result.type = channelType.name;
                }

                const department = departments.find(
                    (dept) =>
                        dept.name.toLowerCase().replace(/\s+/g, '') ===
                        d.department.toLowerCase().replace(/\s+/g, '')
                );
                if (department === undefined) {
                    result.department = `${d.department} (not found)`;
                } else {
                    result.department = department.name;
                }

                return result;
            });
            myGoals.set(year, goal);
        }
        setGoals(myGoals);
        setKey((prevKey) => prevKey + 1);
    };

    async function setSelectedYear(year: string | null) {
        if (year === null || year === '' || year === undefined) {
            return;
        }
        selectedYears.add(year);
        const sortedYearsArray = Array.from(selectedYears.keys()).sort().reverse();
        setYears(new Set(sortedYearsArray));
        await doLocationChange(location_name);
    }

    //light/dark mode
    const bg = useColorModeValue('white.100', '#121212');
    const filterBg = useColorModeValue('white', '#282828');
    const fontColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', '#3f3f3f');

    if (isUploading) {
        return (
            <GoalsUploadForm
                doneUploading={doneUploading}
                location_id={location}
                location_name={location_name}
                goalTypes={goalTypes}
                departments={departments}
                setSelectedYear={setSelectedYear}
            />
        );
    } else {
        return (
            <Box className='goals-page--wrapper' bg={bg}>
                <Flex className='goals-page--header'>
                    <Flex className='goals-header--left'>
                        <Heading className='goals-heading'>GOALS</Heading>
                    </Flex>
                    <Flex className='goals-header--right'>
                        <Flex className='location-select-wrapper'>
                            <Select
                                value={location_name}
                                className='location-select'
                                placeholder='Location'
                                onChange={(e) => handleLocationChange(e)}
                                bg={filterBg}
                                border={borderColor}
                                _placeholder={{ color: fontColor, opacity: 1 }}
                            >
                                {clients.map((client: any) => {
                                    return (
                                        <option value={client.name} key={client.name}>
                                            {client.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </Flex>
                        <Flex className='goals-header--buttons'>
                            <YearSelector
                                className='year-selector'
                                title='Add Goals Year'
                                setSelectedYear={setSelectedYear}
                            />
                            <Button
                                isDisabled={location_name === ''}
                                className='goals-upload-btn primary-btn'
                                onClick={() => {
                                    setUploading(true);
                                }}
                            >
                                Upload Goals
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex className='goals-page--body'>
                    {Array.from(selectedYears).map((year, i) => (
                        <AccordionComponent
                            title={`${year} Goals`}
                            key={`accordion-${year}`}
                        >
                            <GoalsTable
                                key={myKey + i}
                                data={goals.get(year) === undefined ? [] : goals.get(year)}
                                year={year}
                                location_name={location_name}
                                goalTypes={goalTypes}
                                departments={departments.map((dept) => {
                                    return { id: dept.DepartmentId, name: dept.name };
                                })}
                                columnVisibility={columnVisibility}
                                setColumnVisibility={updateColumnVisibility}
                            />
                        </AccordionComponent>
                    ))}
                </Flex>
            </Box>
        );
    }
}
export default Goals;
