import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface AddressAutocompleteProps {
    control: any;
    name: any;
    label?: string;
    placeholder: any;
    isRequired?: boolean;
    onChange: any;
    bg: any;
    color: any;
    className?: string;
}

export default function AddressAutocomplete({
    control,
    name,
    label,
    placeholder,
    isRequired,
    onChange,
    bg,
    color,
    className
}: AddressAutocompleteProps) {
    const initAutocomplete = (inputElement) => {
        if (!inputElement || !window.google) return;

        const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
            componentRestrictions: { country: 'US' },
            fields: ['address_components', 'formatted_address', 'geometry']
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.address_components) return;

            let streetNumber = '';
            let streetName = '';

            const addressData = {
                address: '',
                lat: place.geometry.location?.lat(),
                lng: place.geometry.location?.lng(),
                city: '',
                state: '',
                zip: ''
            };

            place.address_components.forEach(component => {
                const types = component.types;
                if (types.includes('street_number')) streetNumber = component.long_name;
                if (types.includes('route')) streetName = component.long_name;
                if (types.includes('locality')) addressData.city = component.long_name;
                if (types.includes('administrative_area_level_1')) addressData.state = component.short_name;
                if (types.includes('postal_code')) addressData.zip = component.long_name;
            });

            // Combining these two to get the street address to populate the way we want
            addressData.address = `${streetNumber} ${streetName}`.trim();

            if (onChange) {
                onChange(addressData);
            }
        });
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl variant='floating' isRequired={isRequired}>
                    <Input
                        {...field}
                        ref={(input) => {
                            if (input) initAutocomplete(input);
                        }}
                        height='45px'
                        bg={bg}
                        color={color}
                    />
                    <FormLabel style={{ paddingRight: '8px' }}>{placeholder || label}</FormLabel>
                </FormControl>
            )}
        />
    );
}